import React from "react";
import { Link } from "react-router-dom";

const SingleHub = ({ img, text, title, onClick, currency, price }) => {
  return (
    <div className="singleHub">
      <div
        /* data-bs-toggle="offcanvas"
        href="#offcanvasLeft"
        role="button"
        aria-controls="offcanvasExample" */
      >
        <img className="img-fluid" src={img} alt="drip" />
      </div>
      <div className="info mt-5">
        <h3>{text}</h3>
        <h1>{title}</h1>
        <div className="d-flex align-items-center justify-content-between mt-3">
          <Link
            to="#offcanvasLeft"
            className="main-btn"
            data-bs-toggle="offcanvas"
            role="button"
        aria-controls="offcanvasExample"
        onClick={onClick}
          >
            Know More
          </Link>
          <div className="price">
            {currency} <span>{price}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleHub;
