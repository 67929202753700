import React from "react";
import { Link } from "react-router-dom";
import { FiChevronRight } from "react-icons/fi";
import cn from "classnames";

const LeftModal = ({
  id,
  dripsImg,
  boosterImg,
  aestheticImg,
  title,
  duration,
  startingAmount,
  slogan,
  para1,
  howWorks,
  para2,
  concernInfo,
  benefitTitle,
  benefitList,
  keyInstrument,
  currency,
  price,
}) => {
  return (
    <div
      className="offcanvas offcanvas-start"
      tabIndex="-1"
      id="offcanvasLeft"
      aria-labelledby="offcanvasExampleLabel"
    >
      <div className="offcanvas-body">
        <button
          type="button"
          className="btn-close text-reset"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
        <div className="img-wrap">
          {dripsImg && (
            <img className="img-fluid drips-img" src={dripsImg} alt="drip" />
          )}
          {boosterImg && (
            <img
              className="img-fluid booster-img"
              src={boosterImg}
              alt="drip"
            />
          )}
          {aestheticImg && (
            <img className="aesthetic-img" src={aestheticImg} alt="aesthetic" />
          )}
        </div>
        <div
          className={cn("title mt-5", { ["aesthetic-title"]: aestheticImg })}
        >
          {title}
        </div>
        {duration && (
          <div className="d-flex duration gap-1">
            {duration} min duration | Starting at AED {startingAmount}
          </div>
        )}
        {slogan && <div className="slugan mt-4">{slogan}</div>}
        {!aestheticImg && <h3 className="mt-4">About</h3>}
        <p className="details">{para1 || howWorks}</p>
        <br />
        {para2 && <p>{para2}</p>}
        {concernInfo && <div className="concern-info">
            <h2>Treatable concern</h2>
            <div className="d-flex align-items-center gap-3 flex-wrap">
            {concernInfo.map((x, index) => (
              <div className="d-flex align-items-center gap-3" key={index}>
                <img src={x.icon} alt="icon" className="concern-icon" />
                <p>{x.text}</p>
              </div>
            ))}
            </div>
          </div>}
        {benefitTitle && (
          <div className="benefit">
            <h2>{benefitTitle}</h2>
            <ul>
              {benefitList.map((x, index) => (
                <li key={index}>{x}</li>
              ))}
            </ul>
          </div>
        )}
        {keyInstrument && (
          <div className="key">
            <h2>key Ingredients:</h2>
            <p>{keyInstrument}</p>
          </div>
        )}
        <div className="d-flex align-items-center bottom gap-4 mt-5">
          {price && <div className="price">
            {currency} <span>{price}</span>
          </div>}
    

          <Link
            className="btn main-btn mt-0" to="https://ivhub.com/book/"
          
          >
            <span>Book Now</span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default LeftModal;
