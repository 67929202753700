import React from "react";
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import Page from "../Components/Page";
import Home from "./Home";
import Drip from "./Drip";
import DripDetails from "./DripDetails";
import Booster from "./Booster";
import Membership from "./Membership";
import Contact from "./Contact";
import WhoWeAre from "./WhoWeAre";
import CancellationPolicy from "./CancellationPolicy";
import TermsAndCondition from "./TermsAndCondition";
import AestheticDetails from "./AestheticDetails";
import BoosterDetails from "./BoosterDetails";
import Aesthetic from "./Aesthetic";
import Failed from "./Failed";
import Thankyou from "./Thankyou";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <Page 
        content={
            <Home />
        }
      />
    ),
  },
  {
    path: "/services/drips/wellness-iv-drip",
    element: (
      <Page 
        content={
            <Drip activeWellnessIvDrips />
        }
      />
    ),
  },
  {
    path: "/services/drips/recovery-hangover-iv-rips",
    element: (
      <Page 
        content={
            <Drip activeRecoverIvDrips />
        }
      />
    ),
  },
  {
    path: "/services/drips/nad+",
    element: (
      <Page 
        content={
            <Drip activeNadIvDrips />
        }
      />
    ),
  },
  {
    path: "/iv-therapy/view-all-drips",
    element: (
      <Page 
        content={
            <Drip activeAllIvDrips />
        }
      />
    ),
  },
  {
    path: "/drip-details/:url",
    element: (
      <Page 
        content={
            <DripDetails />
        }
      />
    ),
  },
  {
    path: "/booster-details",
    element: (
      <Page 
        content={
            <BoosterDetails />
        }
      />
    ),
  },
  {
    path: "/booster-details/:url",
    element: (
      <Page 
        content={
            <BoosterDetails />
        }
      />
    ),
  },
  {
    path: "/view-all-aesthetic",
    element: (
      <Page 
        content={
            <Aesthetic />
        }
      />
    ),
  },
  {
    path: "/aesthetic-details/:url",
    element: (
      <Page 
        content={
            <AestheticDetails />
        }
      />
    ),
  },
  {
    path: "/who-we-are",
    element: (
      <Page 
        content={
            <WhoWeAre />
        }
      />
    ),
  },
  {
    path: "/iv-therapy/view-all-boosters",
    element: (
      <Page 
        content={
            <Booster />
        }
      />
    ),
  },
  {
    path: "/membership",
    element: (
      <Page 
        content={
            <Membership />
        }
      />
    ),
  },
  {
    path: "/contact",
    element: (
      <Page 
        content={
            <Contact />
        }
      />
    ),
  },
  {
    path: "/cancellation-policy",
    element: (
      <Page 
        content={
            <CancellationPolicy />
        }
      />
    ),
  },
  {
    path: "/terms-and-condition",
    element: (
      <Page 
        content={
            <TermsAndCondition />
        }
      />
    ),
  },
  {
    path: "/Failed",
    element: (
      <Page 
        content={
            <Failed />
        }
      />
    ),
  },
  {
    path: "/Thankyou",
    element: (
      <Page 
        content={
            <Thankyou />
        }
      />
    ),
  },
  {
    path: "*",
    element: (
      <Navigate to="/" />
    ),
  },
]);

const Routing = () => {
  return <RouterProvider router={router} />;
};

export default Routing;
