import React from 'react';
import BecomeAMember from '../Components/BecomeAMember';

const WhoWeAre = () => {
    return (
        <div className='who-we-are-page'>
            <div className="hero">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="hero-wrapper d-flex flex-column justify-content-center py-5">
                            <h1>Your Beauty & Wellness <br /> Ally</h1>
                            <p>In an industry that can be overwhelming and often overrun with misinformation, false promises, and asterisks, we founded IV Wellness Lounge to help you understand what's what.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="details">
                <div className="details-bg"></div>
                <div className="container details-wrap">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="para-1">
                            <h1>A Haven For High-Tech Beauty & Wellness Services</h1>
                            <p>We founded our company to demystify cosmetic dermatology and make it more accessible. We believe everyone should look & feel their best based on their own skin & wellness goals, so we put together a carefully curated collection of the safest medically-tested procedures that deliver optimal results.</p>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="para-2">
                            <h1>The Modern Way to Take Care of Your Skin & Health</h1>
                            <p>We believe in natural-looking - not overdone - results, so we've created a menu of the most powerful treatments that you can receive in an hour or less, paired with a product regimen to help your results last. Designed to help you look like yourself, but better - refreshed, rejuvenated and renewed.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="services py-5">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h2>How We Evaluate Our Services</h2>
                        </div>
                        <div className="col-lg-4 my-5">
                            <h1>1<span>Safety</span></h1>
                            <p>Your safety is our number one priority, which is why our services are curated for safety. And also why they'll only ever be performed on you by a medical professional.</p>
                        </div>
                        <div className="col-lg-4 my-5">
                            <h1>2<span>Medical Validation</span></h1>
                            <p>No pseudoscience regarded as “evidence” or miracle claims here. We take testing seriously, so our services are only ever ones backed by sound scientific research.</p>
                        </div>
                        <div className="col-lg-4 my-5">
                            <h1>3<span>Powerful Results</span></h1>
                            <p>Forget recovery time - we only select services that give efficacy with little to no down time so that you can easily fit your appointment into a lunch break.</p>
                        </div>
                    </div>
                </div>
            </div>
            <BecomeAMember />
        </div>
    );
};

export default WhoWeAre;