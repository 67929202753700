import React, { useState, useEffect } from "react";
import { MdOutlineWatchLater } from "react-icons/md";
import { IoLocationOutline } from "react-icons/io5";
import { BsEnvelope } from "react-icons/bs";
import { FaWhatsapp } from "react-icons/fa";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Contact = () => {
  const [btnTxt, setBtnTxt] = useState("Send");
  const [formSubject, setFormSubject] = useState()

  const onSubmit = async (event) => {
    event.preventDefault();
    setBtnTxt("Sending....");
    const formData = new FormData(event.target);

    formData.append("access_key", "265beaa4-640b-44cc-abe8-87fd08d34450");

    const res = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      body: formData,
    }).then((res) => res.json());

    if (res.success) {
      //console.log("Success", res);
      toast(res.message);
      setBtnTxt("Send");
    } else {
      //console.log("Error", res);
      toast(res.message);
      setBtnTxt("Send");
    }
  };

  return (
    <div className="contact-page py-5">
      <div className="container">
        <div className="hero">
          <div className="hero-bg"></div>
          <div className="row">
            <div className="col-lg-6 mt-5">
              <div className="left">
                <h1>
                  Need assistance? <br /> <span>We're here to help.</span>
                </h1>
                <p>
                  If you have any questions about our services or would like to enquire about memebership.
                </p>
                <div className="row">
                  <div className="col-lg-6"></div>
                  <div className="col-lg-6">
                    <div className="open-time d-flex mt-5">
                      <span className="icon">
                        <MdOutlineWatchLater />
                      </span>
                      <span className="text">
                        Everyday <br />
                        10AM - 07PM (Clinic)<br/>
                        08AM - 12AM (Conceirge)
                      </span>
                    </div>
                    <div className="contact-info-wrap mt-5">
                      <div className="contact-info pt-5">
                        <div className="single-info d-flex gap-3 align-items-center">
                          <IoLocationOutline />
                          <a href="https://goo.gl/maps/hp5rnKhHnarTpLxe8" className="footer-link">Gate District 2, DIFC, Dubai</a>
                        </div>
                        <div className="single-info d-flex gap-3 align-items-center">
                          <BsEnvelope />
                          <Link to="mailto:hello@ivhub.com">
                            hello@ivhub.com
                          </Link>
                        </div>
                        <div className="single-info d-flex gap-3 align-items-center">
                          <FaWhatsapp />
                          <Link to="tel:971585748444">+971-58-574-8444</Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 d-none d-lg-block mt-5">
              <div className="right">
                <img
                  src="/img/contact/1.png"
                  alt=""
                  className="img-fluid px-lg-5"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="contact-form">
          <form onSubmit={onSubmit} className="form" method="POST">
            <input type="hidden" name="from_name" value="Iv Hub Contact" />
            <input
              type="hidden"
              name="subject"
              value={formSubject}
            />
            <h1 className="text-center mb-5">We'd love to hear from you</h1>
            <div className="row">
              <div className="col-sm-6 mt-4">
                <input
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Name"
                  required
                />
              </div>
              <div className="col-sm-6  mt-4">
                <input
                  type="text"
                  name="Subject"
                  id="subject"
                  placeholder="Subject"
                  onChange={(e) => setFormSubject(e.target.value)}
                  required
                />
              </div>
              <div className="col-sm-6 mt-4">
                <input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Email"
                  required
                />
              </div>
              <div className="col-sm-6 mt-4">
                <input
                  type="tel"
                  name="phone"
                  id="phone"
                  placeholder="Phone Number"
                  required
                />
              </div>
              <div className="col-12 mt-4">
                <textarea
                  name="message"
                  id="message"
                  rows="5"
                  placeholder="Message"
                  required
                ></textarea>
              </div>
              <div className="col-12 text-center">
                <button type="submit" className="main-btn btn">
                  {btnTxt}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <ToastContainer position="bottom-right" />
    </div>
  );
};

export default Contact;
