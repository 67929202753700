import React, { useState } from "react";
import { Link } from "react-router-dom";
import aestheticDetails from "../mocks/aestheticDetails.json";
import LeftModal from "../Components/LeftModal";
import Consultation from '../Components/Consultation'

const priceTable = [
 
];

const Aesthetic = () => {
    const [currentKnowMoreAesthetic, setCurrentKnowMoreAesthetic] = useState(aestheticDetails[0])
  return (
    <div className="aesthetic">
      {/* hero */}
      <div className="hero py-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="left text-center text-light">
                <h1>Summer Care Packages</h1>
                <p>
                  Look and feel your best all summer long with our skin, body,
                  and wellness treatments.
                </p>
              </div>
            </div>
            <div className="col-lg-6 mt-5 mt-lg-0">
              <div className="right">
                <div className="card text-light p-3">
                  <div className="d-flex gap-2 align-items-center">
                    <div className="spend head"> <a href="https://wa.me/971585748444">Get in touch to know more</a></div>
                    <div className="dots head">
                      ..</div>
                    <div className="get head"></div>
                  </div>
                  {priceTable.map((x, index) => (
                    <div
                      className="d-flex gap-2 align-items-center"
                      key={index}
                    >
                      <div className="spend">AED{x.spend}+</div>
                      <div className="dots">
                        .........................................................................................................................................................................................................................
                      </div>
                      <div className="get">AED{x.get}</div>
                    </div>
                  ))}
                </div>
                <p className="note mt-4 text-light">
                  *Offer valid for first 30 appointments{" "}
                  <Link className="text-light" to="/terms-and-condition">
                    View Terms
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* All aesthetic */}
      <div className="all-aesthetic my-5">
        <div className="container">
          <div className="row">
            {aestheticDetails.map((x, index) => (
              <div className="col-lg-4 col-md-6 mt-5" key={index}>
                <div className="card text-center px-3 pt-3">
                  <h1 className="border-bottom pb-2 mb-3">{x.title}</h1>
                  <div className="d-flex align-items-center justify-content-center mb-3 gap-2 flex-wrap">
                    {x?.concernInfo.map((y, yIndex) => (
                      <img
                        key={yIndex}
                        className="img-fluid concern-icon"
                        src={y.icon}
                        alt="icon"
                      />
                    ))}
                  </div>
                  <p>{x.secondTitle}</p>
                  <Link
                    to="#offcanvasLeft"
                    className="main-btn"
                    data-bs-toggle="offcanvas"
                    role="button"
                    aria-controls="offcanvasExample"
                    onClick={() => setCurrentKnowMoreAesthetic(x)}
                  >
                    Know More
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="pt-5">
      <Consultation />
      </div>
      <LeftModal 
        aestheticImg={currentKnowMoreAesthetic?.heroImg}
        title={currentKnowMoreAesthetic?.title}
        duration={currentKnowMoreAesthetic?.duration}
        startingAmount={currentKnowMoreAesthetic?.startingAmount}
        howWorks={currentKnowMoreAesthetic?.howWorks}
        concernInfo={currentKnowMoreAesthetic?.concernInfo}
        id={currentKnowMoreAesthetic?.id}
      />
    </div>
  );
};

export default Aesthetic;
