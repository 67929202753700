import React from 'react';

const Membership = () => {
    return (
        <div className='container my-5'>
            this is membership page
        </div>
    );
};

export default Membership;