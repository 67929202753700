import React from "react";
import { Link } from "react-router-dom";
import { BsInstagram } from "react-icons/bs";
import { RiFacebookFill } from "react-icons/ri";
import Whatsapp from "./Whatsapp";

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-top py-5">
        <div className="container">
          <div className="row row-cols-1 row-cols-lg-5">
            <div className="col text-center text-lg-start align-self-center px-lg-5">
              <img
                src="/img/footer-logo.png"
                alt=""
                className="img-fluid footer-logo"
              />
            </div>
            <div className="col text-center text-lg-start mt-5 mt-lg-0">
              <ul className="list-unstyled d-flex flex-column gap-3">
                <li className="footer-title">Shops</li>
                <li>
                  <Link to="/iv-therapy/boosters/view-all" className="footer-link">
                    Boosters
                  </Link>
                </li>
                <li>
                  <Link to="/iv-therapy/drips/view-all" className="footer-link">
                    Drips
                  </Link>
                </li>
                <li>
                  <Link to="/aesthetic-details" className="footer-link">
                    Aesthetic Services
                  </Link>
                </li>
                <li>
                  <Link to="/contact" className="footer-link">
                    Membership
                  </Link>
                </li>
                <li className="mt-4">
                  <Link to="mailto:hello@ivhub.com" className="footer-link">
                    hello@ivhub.com
                  </Link>
                </li>
                <li>
                  <Link to="tel:+971585748444" className="footer-link">
                    +971-585 748 444
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col text-center text-lg-start mt-5 mt-lg-0">
              <ul className="list-unstyled d-flex flex-column gap-3">
                <li className="footer-title">Learn</li>
                <li>
                  <Link to="/who-we-are" className="footer-link">
                    Who We Are
                  </Link>
                </li>
                <li>
                  <Link to="/cancellation-policy" className="footer-link">
                    Cancellation Policy
                  </Link>
                </li>
                <li>
                  <Link to="/terms-and-condition" className="footer-link">
                    Terms & Conditions
                  </Link>
                </li>
                <li>
                  <Link to="/contact" className="footer-link">
                    Have Questions?
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col text-cente text-lg-start mt-5 mt-lg-0">
              <ul className="list-unstyled text-center text-lg-start d-flex flex-column gap-3">
                <li className="footer-title">Visit Us</li>
                <li>  
                   <a href="https://goo.gl/maps/hp5rnKhHnarTpLxe8" className="footer-link">Gate District 2, DIFC, Dubai</a>
                </li>
                <li>
                  <Link
                    to="https://www.instagram.com/ivhubuae"
                    target="_blank"
                    className="footer-link"
                  >
                    <BsInstagram />
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col align-self-center mt-5 mt-lg-0">
              <ul className="list-unstyled footer-social d-flex justify-content-center justify-content-lg-start gap-3">
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom py-3">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="d-flex flex-column flex-lg-row gap-3 gap-lg-0 align-items-center justify-content-between">
                <p>&copy; {new Date().getFullYear()} All rights reserved</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Whatsapp />
    </div>
  );
};

export default Footer;
