import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { FiChevronRight } from "react-icons/fi";
import boosters from '../mocks/boosters.json';
import Consultation from "../Components/Consultation";

const BoosterDetails = () => {
  const {url} = useParams()
  const [currentBooster, setCurrentBooster] = useState([])
  
  useEffect(() => {
    boosters.find((x) => {
      if(x.url ===  `/booster-details/${url}`){
        setCurrentBooster(x)
      }
    })
  })

  return (
    <div className="booster-details-page">
      {/* hero section */}
         
      {/* Hub section */}
      <div className="booster-hub py-5">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="d-flex flex-column flex-lg-row align-items-lg-center justify-content-between">
                <div>
                  <h1>{currentBooster?.title}</h1>
                  <h4>{currentBooster?.name}</h4>
                </div>
                <div className="d-flex flex-column align-items-lg-end">
                  <Link className="btn main-btn" to="/iv-therapy/boosters/view-all">
                    <span>Explore Other Boosters</span>
                    <FiChevronRight />
                  </Link>
                  <Link to="/" className="more-session">
                    20% discount on 3 or more sessions
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="row align-items-center py-5">
            <div className="col-lg-5 mt-5">
              <div className="left text-center">
                <img src={currentBooster?.img} className="img-fluid" alt="" />
              </div>
            </div>
            <div className="col-lg-7 mt-5">
              <div className="right">
                <p>{currentBooster?.text}</p>
                <div className="d-flex align-items-center gap-4 mt-5">
                  <Link
                    className="btn main-btn mt-0"
                    to="#"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                  >
                    <span>Book Now</span>
                    <FiChevronRight />
                  </Link>
                  <div className="price">
                    {currentBooster?.currency} <span>{currentBooster?.price}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Consultation />
    </div>
  );
};

export default BoosterDetails;
