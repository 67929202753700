import React from "react";
import { WhatsAppWidget } from 'react-whatsapp-widget';
import 'react-whatsapp-widget/dist/index.css';

const Whatsapp = () => {
  return (
    <div className="whatsapp">
      <WhatsAppWidget companyName="IV Wellness Lounge"  phoneNumber="+971585748444" />
    </div>
  );
};

export default Whatsapp;
