import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { FiChevronRight } from "react-icons/fi";
import wellnessIvDrip from "../mocks/wellnessIvDrip.json";
import recoveryIvDrip from "../mocks/recoveryIvDrip.json"
import nadPlusIvDrip from '../mocks/nadPlusIvDrip.json'
import Consultation from "../Components/Consultation";

const allIvDrips = wellnessIvDrip.concat(recoveryIvDrip.concat(nadPlusIvDrip))

const DripDetails = () => {
  const {url} = useParams()
  const [currentDrips, setCurrentDrips] = useState([])
  console.log(currentDrips)
  
  useEffect(() => {
    allIvDrips.find((x) => {
      console.log(url)
      if(x.url ===  `/drip-details/${url}`){
        setCurrentDrips(x)
      }
    })
  })

  return (
    <div className="drip-details-page">
      {/* hero section */}
      {currentDrips.id < 14 ? (
        <div className="wellHero">
      
      </div>
      ): (
        <div className="recoverHero">
          <div className="container py-5">
            <div className="row hero-wrap align-items-center">
              <div className="col-lg-6 p-5">
                <div className="left text-center">
                  <h3>Book your next service today</h3>
                  <h1>
                    {currentDrips.id < 16 ? "Recovery & Hangover" : "NAD+"}
                    <em>IV Drips</em>
                  </h1>
                  <p>
                    20% discount on 3 or more <br /> sessions
                  </p>
                </div>
              </div>
              <div className="col-lg-6 align-self-end">
                <div className="right text-center text-lg-end">
                  <img
                    className="mt-5 img-fluid drip-bg"
                    src=
                    {currentDrips.id < 16 ? "/img/drip/drip-hero2.png" : "/img/drip/drip-hero4.png"}
                    alt="drip-bg"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* Hub section */}
      <div className="drip-hub py-5">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="d-flex flex-column flex-lg-row align-items-lg-center justify-content-between">
                <div>
                  <h1>{currentDrips?.title}</h1>
                  <h4>{currentDrips?.subTitle}</h4>
                </div>
                <div className="d-flex flex-column align-items-lg-end">
                  <Link className="btn main-btn" to="/iv-therapy/drips/view-all">
                    <span>Explore Other Drips</span>
                    <FiChevronRight />
                  </Link>
                  <Link to="/" className="more-session">
                    20% discount on 3 or more sessions
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="row align-items-center py-5">
            <div className="col-lg-5 mt-5">
              <div className="left text-center">
                <img src={currentDrips?.img} className="img-fluid" alt="" />
              </div>
            </div>
            <div className="col-lg-7 mt-5">
              <div className="right">
                <p>{currentDrips?.paraOne}</p>
                <br />
                <p>{currentDrips?.paraTwo}</p>
                <div className="d-flex align-items-center gap-4 mt-5">
           
                  <div className="price">
                    {currentDrips?.currency} <span>{currentDrips?.price}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* weight drip */}
      <div className="weight-drip py-5">
        <div className="container pb-5">
          <div className="row p-5">
            <div className="col-12">
              <h1>When to Drip</h1>
            </div>
          </div>
          <div className="row justify-content-center flex-wrap">
            {currentDrips?.formula?.map((x, index) => (
              <div className="col position-relative mt-5" key={index}>
                <div className="single-drip d-flex flex-column align-items-center gap-4">
                  <img src={x.icon} alt="icon" />
                  <p>{x.text}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* Fat burn */}
      <div className="fat-burn py-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="left">
                <h1>{currentDrips?.slogan}</h1>
              </div>
            </div>
            <div className="col-lg-6 mt-5 mt-lg-0">
              <div className="right">
                <h2>{currentDrips?.benefit?.title}</h2>
                <ul>
                  {currentDrips?.benefit?.list.map((x, index) => (
                    <li key={index}>{x}</li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="col-12 mt-5">
              <div className="bottom">
                {currentDrips?.otherInfo?.map((x, index) => (
                  <div key={index} className="fat-burn-info-wrap">
                    <div className="fat-burn-info d-flex flex-column flex-lg-row py-5">
                      <h1>{x.title}</h1>
                      <p className="mt-5 mt-lg-0">{x.info}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Consultation />
    </div>
  );
};

export default DripDetails;
