import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { FiChevronRight } from "react-icons/fi";
import cn from "classnames";
import BecomeAMember from "../Components/BecomeAMember";
import aestheticDetails from "../mocks/aestheticDetails.json";
import Consultation from "../Components/Consultation";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
  allScreen: {
    breakpoint: { max: 8000, min: 0 },
    items: 1,
  },
};

const prePostTreatment = ["Pre-treatment Tips", "Post-treatment Tips"];

const AestheticDetails = () => {
  const [activeIndex, setActiveIndex] = useState(1);
  const { url } = useParams();
  const [currentAesthetic, setCurrentAesthetic] = useState([]);
  const [keyText, setKeyText] = useState(
    "The most common area that we treat. While your face is relaxed, we address any inset lines or unevenness."
  );
  const strings = currentAesthetic?.hello;

  const [randomString, setRandomString] = useState([]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const randomIndex = Math.floor(Math.random() * strings.length);
      setRandomString(strings[randomIndex]);
    }, 2000);

    return () => {
      clearInterval(intervalId);
    };
  });

  useEffect(() => {
    aestheticDetails.find((x) => {
      if (x.url === `/aesthetic-details/${url}`) {
        setCurrentAesthetic(x);
      }
    });
  });

  return (
    <div className="aesthetic-details-page">
      <div className="hero py-5">
        <div className="container">
          <div className="hero-wrapper">
            {currentAesthetic.heroImg2 ? (
              <div className="hero-bg">
                <Carousel
                autoPlay={true}
                autoPlaySpeed={5000}
                infinite={true}
                showDots={false}
                arrows={false}
                responsive={responsive}
              >
                <img
                  className="hero-bg-img"
                  src={currentAesthetic.heroImg}
                  alt="aesthetic"
                />
                <img
                  className="hero-bg-img"
                  src={currentAesthetic.heroImg2}
                  alt="aesthetic"
                />
              </Carousel>
              </div>
            ) : (
              <img
                className="hero-bg"
                src={currentAesthetic.heroImg}
                alt="aesthetic"
              />
            )}
            <div className="wrap px-5">
              <div className="row">
                <div className="col-12">
                  <h1>{currentAesthetic.heroTitle}</h1>
                  <div className="min-wrap d-flex border">
                    <div className="min">
                      {currentAesthetic.duration} min duration
                    </div>
                    <div className="min">
                      Starting at AED{currentAesthetic.startingAmount}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="refresh-wrap py-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-1"></div>
            <div className="col-lg-10">
              <div className="d-flex top justify-content-between flex-column flex-lg-row">
                <h3>
                  Hello{" "}
                  {randomString}
                </h3>

                
              </div>
              <div className="bottom border mt-5">
                <h3 className="border-bottom py-4 mx-4">
                  {currentAesthetic.secondTitle}
                </h3>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="px-4">
                      <h6>How it works...</h6>
                      <p>{currentAesthetic.howWorks}</p>
                    </div>
                  </div>
                  <div className="col-lg-6 px-4">
                    <div className="px-4">
                      <h6>Why we love it...</h6>
                      <p>{currentAesthetic.whyLove}</p>
                    </div>
                  </div>
                </div>
                <div className="border-top single-info-wrap mt-3 p-4">
                  <div className="d-flex align-items-center gap-3">
                    <h4>Treatable Concerns</h4>
                    <div className="d-flex align-items-center gap-3 flex-wrap">
                      {currentAesthetic?.concernInfo?.map((x, index) => (
                        <div
                          className="single-concern d-flex gap-2 align-items-center"
                          key={index}
                        >
                          <img src={x.icon} alt="icon" />
                          <span>{x.text}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-1"></div>
          </div>
        </div>
      </div>
      <div className="personalize py-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="left">
                <img
                  className="img-fluid"
                  src="/img/aesthetic/personalize.jpg"
                  alt=""
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="right mt-5">
                <h1>{currentAesthetic?.personalize?.heading}</h1>
                {currentAesthetic?.personalize?.infos?.map((x, index) => (
                  <div className="border p-3 mt-4" key={index}>
                    <h1 className="pb-3 title border-bottom">{x.title}</h1>
                    <ul>
                      <li className="my-2">{x.duration && x.duration}</li>
                      {x.details &&
                        x.details.map((y, yIndex) => (
                          <li
                            key={yIndex}
                            className="d-flex justify-content-between"
                          >
                            <p>{y.text}</p>
                            <p> {y.amount} AED </p>
                          </li>
                        ))}
                    </ul>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* treatable area one */}
      {currentAesthetic?.treatableOne && (
        <div className="treatable-area-page">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h1 className="text-center">Treatable Area</h1>
                {currentAesthetic?.treatableOne?.map((x, index) => (
                  <div className="text-center" key={index}>
                    <h4 className="mt-5 mb-3">{x.title}</h4>
                    <div className="d-flex align-items-center justify-content-center gap-3 flex-wrap">
                      {x?.text?.map((y, yIndex) => (
                        <h2 key={yIndex}>{y}</h2>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
      {/* treatable area two */}
      {currentAesthetic?.treatableTwo && (
        <div className="treatable-area-two">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="left pe-5">
                  <h1>Treatable areas</h1>
                  <h4 className="mt-5 mb-3">
                    INTERACT WITH THE AREAS FOR MORE DETAILS
                  </h4>
                  <p>{keyText}</p>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="right mt-5 mt-lg-0">
                  <div className="d-flex gap-3 flex-wrap">
                    {currentAesthetic?.treatableTwo?.map((x, index) => (
                      <button key={index} onClick={() => setKeyText(x.text)}>
                        {x.key}
                      </button>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="questions py-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-1"></div>
            <div className="col-lg-10">
              <h1>Questions? We've Got Answers.</h1>
              <div className="accordion mt-5" id="accordionExample">
                {currentAesthetic?.questions?.map((x, index) => (
                  <div className="accordion-item" key={index}>
                    <h2 className="accordion-header" id={"heading" + index}>
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={"#collapse" + index}
                        aria-expanded="false"
                        aria-controls={"collapse" + index}
                      >
                        {x.qsn}
                      </button>
                    </h2>
                    <div
                      id={"collapse" + index}
                      className="accordion-collapse collapse"
                      aria-labelledby={"heading" + index}
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">{x.ans}</div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pre-post py-5">
        <div className="container">
          <div className="head">
            {prePostTreatment.map((x, index) => (
              <div
                onClick={() => setActiveIndex(index + 1)}
                className={cn("title pb-3", {
                  active: activeIndex === index + 1,
                })}
                key={index}
              >
                {x}
              </div>
            ))}
          </div>
          <div className="row">
            {activeIndex === 1
              ? currentAesthetic?.preTreatment?.map((x, index) => (
                  <div key={index} className="col-lg-4">
                    <div className="border d-flex flex-column align-items-center justify-content-center gap-4 text-center mt-5 p-4">
                      <img src={x.icon} alt="icon" />
                      <p>{x.text}</p>
                    </div>
                  </div>
                ))
              : currentAesthetic?.postTreatment?.map((x, index) => (
                  <div key={index} className="col-lg-4">
                    <div className="border d-flex flex-column align-items-center justify-content-center gap-4 text-center mt-5 p-4">
                      <img src={x.icon} alt="icon" />
                      <p>{x.text}</p>
                    </div>
                  </div>
                ))}
          </div>
        </div>
      </div>
      <div className="pt-5">
        <Consultation />
      </div>
      <BecomeAMember />
    </div>
  );
};

export default AestheticDetails;
