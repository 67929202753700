import React, { useEffect, useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { BsFillTelephoneFill } from "react-icons/bs";
import { FaEnvelope } from "react-icons/fa";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import wellnessIvDrip from "../mocks/wellnessIvDrip.json";
import recoveryIvDrip from "../mocks/recoveryIvDrip.json";
import nadPlusIvDrip from "../mocks/nadPlusIvDrip.json";
import { FiPlusSquare } from "react-icons/fi";

const allIvDrips = wellnessIvDrip.concat(recoveryIvDrip.concat(nadPlusIvDrip));

const Booking = () => {
  const [btnTxt, setBtnTxt] = useState("Submit");
  const [heartStatus, setHeartStatus] = useState("No");
  const [pregnancyStatus, setPregnancyStatus] = useState("No");
  const [showSelectDrips, setShowSelectDrips] = useState(true);
  const [dripGettingLocation, setDripGettingLocation] =
    useState("Home/Office/Hotel");
  const [price, setPrice] = useState(
    allIvDrips[0].price + " " + allIvDrips[0].currency
  );
  const [selectedDrips, setSelectedDrips] = useState([]);

  useEffect(() =>
    allIvDrips.find((x) => {
      if (x.title === selectedDrips) {
        setPrice(x.price + " " + x.currency);
      }
    })
  );

  const onSubmit = async (event) => {
    event.preventDefault();
    setBtnTxt("Submitting....");
    const formData = new FormData(event.target);

    formData.append("access_key", "7b3e76ac-f5c1-4ec3-b207-54ff36b4d857");

    const res = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      body: formData,
    }).then((res) => res.json());

    if (res.success) {
      //console.log("Success", res);
      toast(res.message);
      setBtnTxt("Submit");
    } else {
      //console.log("Error", res);
      toast(res.message);
      setBtnTxt("Submit");
    }
  };
  return (
    <div
      className="modal fade"
      id="exampleModal"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <button
            type="button"
            className="btn-close booking-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
          <div className="modal-body">
            <div className="booking-page p-3">
              <form
                onSubmit={onSubmit}
                method="POST"
                className="booking-wrapper"
              >
                <input type="hidden" name="from_name" value="Iv Hub Booking" />
                <input type="hidden" name="subject" value="Booking" />
                <div className="row">
                  <div className="col-12">
                    <h1>Booking</h1>
                    <div className="d-none d-lg-flex justify-content-center gap-5 mt-4">
                      <div className="direct-contact d-flex gap-2">
                        <div className="icon">
                          <BsFillTelephoneFill />
                        </div>
                        <div className="d-flex flex-column">
                          <span>Phone</span>
                          <Link to="tel:971585748444">+971-58-574-8444</Link>
                        </div>
                      </div>
                      <div className="direct-contact d-flex gap-2">
                        <div className="icon icon2">
                          <FaEnvelope />
                        </div>
                        <div className="d-flex flex-column">
                          <span>Email</span>
                          <Link to="mailto:hello@ivhub.com">
                            hello@ivhub.com
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 select-drips mt-4">
                    {showSelectDrips && (
                      <>
                        <label htmlFor="drips">Select Drip</label>
                        <select
                          className="form-select form-select-sm mt-3"
                          name="drips"
                          id="drips"
                          onChange={(e) => {
                            setSelectedDrips([
                              ...selectedDrips,
                              e.target.value,
                            ]);
                            setShowSelectDrips(false);
                            console.log(e.target)
                          }}
                        >
                          {allIvDrips.map((x, index) => (
                            <option key={index} value={x.title + " " + x.price + " " + x.currency}>
                              {x.title + " " + x.price + " " + x.currency}
                            </option>
                          ))}
                        </select>
                      </>
                    )}
                    {selectedDrips.length > 0 ? (
                      <div className="selected-drips mt-4">
                        <h2>Selected Drips</h2>
                        <ul>
                          {selectedDrips.map((x, index) => (
                            <li key={index}>
                              <span>
                                {index + 1 + ". "}
                                {x}
                              </span>
                              <span>{x.price}</span>
                            </li>
                          ))}
                        </ul>
                      </div>
                    ) : null}
                  </div>
                  {!showSelectDrips && (
                    <div className="col-12 add-another-drips mt-2">
                    <button
                      type="button"
                      className="d-flex gap-1 align-items-center"
                      onClick={() => setShowSelectDrips(true)}
                    >
                      <span className="icon">
                        <FiPlusSquare />
                      </span>
                      <span className="txt1">Add New Drips</span>
                      <span className="txt2">
                        (20% discount on 3 or more sessions)
                      </span>
                    </button>
                  </div>
                  )}
                  <div className="col-lg-6 mt-4">
                    <label htmlFor="">Name</label>
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      id=""
                      name="Patient Name"
                      required
                    />
                  </div>
                  <div className="col-lg-6 mt-4">
                    <label htmlFor="">Email</label>
                    <input
                      type="email"
                      className="form-control form-control-sm"
                      id=""
                      name="Email"
                      required
                    />
                  </div>
                  <div className="col-lg-6 mt-4">
                    <label htmlFor="">Phone</label>
                    <input
                      type="number"
                      className="form-control form-control-sm"
                      id=""
                      name="Phone"
                      required
                    />
                  </div>
                  <div className="col-lg-6 mt-4">
                    <label htmlFor="">Address</label>
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      id=""
                      name="Address"
                      required
                    />
                  </div>
                  <div className="col-lg-6 mt-4">
                    <label htmlFor="">Age</label>
                    <input
                      type="Number"
                      className="form-control form-control-sm"
                      id=""
                      name="Age"
                      required
                    />
                  </div>
                  <div className="col-lg-6 mt-4">
                    <label htmlFor="">
                      Requested Appointment Date and Time
                    </label>
                    <input
                      type="date"
                      className="form-control form-control-sm"
                      id=""
                      name="Requested Appointment Date and Time"
                      required
                      placeholder="Your name"
                    />
                  </div>
                  <div className="col-12 mt-4">
                    <label htmlFor="">
                      Do any of the participants receiving a drip have any
                      significant medical conditions, such as issues with the
                      heart, kidneys or liver, or conditions such as diabetes or
                      cancer?
                    </label>
                    <div className="d-flex gap-4 mt-3">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="Heart, kidneys or liver, or conditions such as diabetes or cancer Status"
                          id="heart1"
                          onChange={(e) =>
                            e.target.checked ? setHeartStatus("Yes") : null
                          }
                          value={heartStatus}
                        />
                        <label className="form-check-label" htmlFor="heart1">
                          Yes
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="Heart, kidneys or liver, or conditions such as diabetes or cancer Status"
                          id="heart2"
                          onChange={(e) =>
                            e.target.checked ? setHeartStatus("No") : null
                          }
                          value={heartStatus}
                        />
                        <label className="form-check-label" htmlFor="heart2">
                          No
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 mt-3">
                    <label htmlFor="">
                      Are you or anyone in your group currently pregnant?
                    </label>
                    <div className="d-flex gap-4 mt-3">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="Pregnancy Status"
                          id="pregnancy1"
                          onChange={(e) =>
                            e.target.checked ? setPregnancyStatus("Yes") : null
                          }
                          value={pregnancyStatus}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="pregnancy1"
                        >
                          Yes
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="Pregnancy Status"
                          id="pregnancy2"
                          onChange={(e) =>
                            e.target.checked ? setPregnancyStatus("No") : null
                          }
                          value={pregnancyStatus}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="pregnancy1"
                        >
                          No
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 mt-3">
                    <label htmlFor="">Where you want to get your Drip?</label>
                    <div className="d-flex gap-4 mt-3">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="Drip getting location"
                          id="getDrip1"
                          onChange={(e) =>
                            e.target.checked
                              ? setDripGettingLocation("Home/Office/Hotel")
                              : null
                          }
                          value={dripGettingLocation}
                        />
                        <label className="form-check-label" htmlFor="getDrip1">
                          Home/Office/Hotel
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="Drip getting location"
                          id="getDrip2"
                          onChange={(e) =>
                            e.target.checked
                              ? setDripGettingLocation("Lounge")
                              : null
                          }
                          value={dripGettingLocation}
                        />
                        <label className="form-check-label" htmlFor="getDrip2">
                          Lounge
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 text-center">
                    <button type="submit" className="main-btn btn mt-3">
                      {btnTxt}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Booking;
