import React from "react";
import { Link } from "react-router-dom";

const SingleBooster = ({ img, text, title, name, onClick, currency, price }) => {
  return (
    <div className="single-booster row align-items-center">
      <div className="col-sm-6 order-2 order-lg-1">
        <div className="info">
          <h1>{title}</h1>
          <h4>{name}</h4>
          <h3>{text}</h3>
          <div className="d-flex align-items-center justify-content-between mt-3">
            <Link
              className="main-btn"
              data-bs-toggle="offcanvas"
              to="#offcanvasLeft"
              role="button"
              aria-controls="offcanvasExample"
              onClick={onClick}
            >
              Know More
            </Link>
            <div className="price">
              {currency} <span>{price}</span>
            </div>
          </div>
        </div>
      </div>
      <div className="col-sm-6 order-1 order-lg-2">
        <div>
          <div className="img-wrap">
            <img className="img-fluid" src={img} alt="booster" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleBooster;
