import React from 'react';

const TermsAndCondition = () => {
    return (
        <div className="termsAndCondition-page py-5">
          <div className="container">
            <div className="hero p-5">
              <h1>
              Terms and Conditions
              </h1>
            </div>
            <h2>Cancellation Policy – Locations</h2>
            <p>
                <strong>TERMS CONDITIONS OF SERVICES, PAYMENT & CONSENT FOR USE AND DISCLOSURE OF PROTECTED HEALTH INFORMATION.
By signing below, you are agreeing to the following Terms and Conditions of our engagement. You must return a signed copy of this document to IV wellness lounge clinic LLC prior to receiving services. The services recipient is referred to herein as “client” or “you”. These terms to which you agree are referred to as “Terms and Conditions” or “Agreement”.</strong> <br /><br />

Insurance Not Accepted; Client’s Responsibility for Payment.
CLIENT UNDERSTANDS AND ACKNOWLEDGES THAT IV wellness lounge clinic LLC AND ITS PERSONNEL ARE NOT PAID OR REIMBURSED FOR THE SERVICES AND HANGOVER MANAGEMENT PROGRAM OR SUPPLEMENTS, VITAMINS OR PHARMACEUTICALS OFFERED BY IV wellness lounge clinic LLC BY MANAGED CARE PLANS, MEDICARE, MEDICAID, OR OTHER THIRD PARTY PAYOR PROGRAMS INCLUDING YOUR HEALTH INSURANCE CARRIER, AND DO NOT ACCEPT INSURANCE FOR SUCH SERVICES. <br />
Clients will be BILLED DIRECTLY and shall be personally responsible for payment, regardless of whether clients are reimbursed by their insurance company, managed care plan or other third party payer. <br /><br />

IV wellness lounge clinic LLC does NOT diagnose or treat any illness, disease or health condition. <br />
Upon entering into these Terms and Conditions, you expressly represent and warrant that you are not engaging IV wellness lounge clinic LLC or its personnel with the expectation that it or they will diagnose or otherwise provide treatment for any illness, disease or condition of any nature. IV wellness lounge clinic LLC personnel will not screen for, diagnose, monitor or otherwise provide any care or treatment for such conditions. IV wellness lounge clinic LLC is relying upon the foregoing representations and warranties upon your entering into these Terms and Conditions and upon IV wellness lounge clinic LLC acceptance of you for the provision of services. <br />
I hereby give my consent for IV wellness lounge clinic LLC to use and disclose protected health information (PHI) about me to carry out treatment, payment and health care operations (TPO) (The Notice of Privacy Practices provided by IV wellness lounge clinic LLC describes such uses and disclosures more completely.) I have the right to review the Notice of Privacy Practices prior to signing this consent. IV wellness lounge clinic LLC reserves the right to revise its Notice of Privacy Practices at any time. A revised Notice of Privacy Practices may be obtained by forwarding a written request to IV wellness lounge clinic LLC. With this consent, IV wellness lounge clinic LLC may call my home or other alternative location and leave a message on voice mail or in person in reference to any items that assist the practice in carrying out TPO, such as appointment reminders, insurance items and any calls pertaining to my clinical care, including laboratory test results, among others. With this consent, IV wellness lounge clinic LLC may mail to my home or other alternative location any items that assist the practice in carrying out TPO, such as appointment reminder cards and patient statements as long as they are marked “Personal and Confidential.” With this consent, IV wellness lounge clinic LLC may e-mail to my home or other alternative location any items that assist the practice in carrying out TPO, such as appointment reminder cards and patient statements. I have the right to request that IV wellness lounge clinic LLC restrict how it uses or discloses my PHI to carry out TPO. The practice is not required to agree to my requested restrictions, but if it does, it is bound by this agreement. <br /><br />

I may revoke my consent in writing except to the extent that the practice has already made disclosures in reliance upon my prior consent. If I do not sign this consent, or later revoke it, IV wellness lounge clinic LLC may decline to provide treatment to me. <br />
By signing this form below, I am consenting to allow IV wellness lounge clinic LLC to use and disclose my PHI to carry out TPO. <br />
INFORMED CONSENT, CLIENT REPRESENTATIONS/WARRANTIES & DISCLAIMER AGREEMENT
I understand that participating in intravenous (IV) hydration, vitamin/supplement administration, pharmaceutical administration, programs and services made available by IV wellness lounge clinic LLC carries risks. <br /><br />

I ACKNOWLEDGE AND AGREE THAT THE SOLE RISK OF INJURY OR HARM RESULTING IN ANY MANNER FROM MY CHOOSING TO PARTICIPATE IN SUCH REGIMEN, PROGRAMS AND SERVICES RESTS ENTIRELY WITH ME TO THE EXTENT THAT I DO NOT DISCLOSE MY HEALTH CONDITIONS, MEDICATIONS OR DRUG USE IN ADVANCE.I expressly represent and warrant to IV wellness lounge clinic LLC that I have never been diagnosed with nor treated for any diseases, illnesses or conditions which may result in increased risk when I participate in regimens, programs or services made available by IV wellness lounge clinic LLC, and I am not choosing to participate with any expectation that IV wellness lounge clinic LLC will screen for, diagnose, monitor or otherwise provide any care or treatment for such conditions.I acknowledge and understand that IV wellness lounge clinic LLC is relying upon the foregoing representations and warranties from me upon JMB Medical Group’s acceptance of me for participation in its drip IV hydration, programs and services.I acknowledge and understand that IV wellness lounge clinic LLC is not responsible or liable for any complications that result from the use of any client provided or custom requested vitamins, injections, or medication. <br /><br />

<strong>RISKS INCLUDE THE FOLLOWING:</strong> <br />
INJURY, BLEEDING, INFECTION, INFLAMMATION/SWELLING, BRUISING OR SCARRING RESULTING FROM IV INFILTRATION, EXTRACTION, EXTRAVASATION AND INJECTION, MISPLACEMENT OF IV LINES IN THE BODY, AIR EMBOLISM, FLUID OVERLOAD, MEDICATION ADVERSE INTERACTIONS, NERVE INJURIES, LIGHTHEADEDNESS OR FAINTING, PAIN OR BURNING DURING INJECTION
YOU EXPRESSLY REPRESENT AND WARRANT TO IV wellness lounge clinic LLC THAT YOU ARE NOT A USER OF ILLEGAL DRUGS AND/OR CONTROLLED SUBSTANCES AND ARE NOT UNDER THE INFLUENCE OF SAME OR RECOVERING FROM USE OF SAME AT THE TIME OF THE PROVISION OF SERVICES TO YOU. <br />
IN THE EVENT OF AN EMERGENCY, CALL HEALTH SERVICE PROVIDER OR PROCEED TO THE NEAREST EMERGENCY ROOM. <br /><br />

<strong>ACKNOWLEDGMENT:</strong> I confirm that I have read this form and fully understand its contents. I acknowledge that no guarantees or assurances have been made to me concerning the results intended from the sessions and programs offered by IV wellness lounge clinic LLC. I understand the nature of the sessions and programs and that participating in them carries risks. I have been given an opportunity to ask questions, and all of my questions have been answered fully and to my satisfaction. I agree to my assumption of all risks associated with my participation. <br />
I have read the above, do agree with these terms and consent to participate in the IV wellness lounge clinic LLC program.</p>
          </div>
        </div>
    );
};

export default TermsAndCondition;