import React from "react";
import Routing from "./Pages/Routing";
import './Styles/style.scss';
import './Styles/componentsStyle.scss';

const App = () => {
  return <Routing />;
};

export default App;
