import React from "react";
import { Link } from "react-router-dom";

const BecomeAMember = () => {
  return (
    <div className="become-a-member">
      <div className="become py-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <div className="left">
                <h1>Become a IV Wellness Lounge Member</h1>
                <p>
                  Step into our exclusive IV Hub Member program and discover a
                  personalized health and wellness lifestyle. This membership
                  program compliments our philosophy which is to design a
                  holistic lifestyle for members and clients which goes beyond
                  treatment. Choose from our two unique membership options to
                  discover a wellness lifestyle with exclusive members-only
                  benefits.
                </p>
                
                <Link className="member-btn btn" to="/contact">
                  Apply Now
                </Link>
              </div>
            </div>
            <div className="col-lg-5 mt-5 mt-lg-0">
              <div className="right text-end">
                <img
                  className="img-fluid member-bg"
                  src="/img/member/bg.png"
                  alt="bg"
                />
                {/* <div className="current-member-wrap">
                  <div className="img-wrap">
                    <img
                      src="/img/member/1.png"
                      alt="member 1"
                      className="member-img"
                    />
                    <img
                      src="/img/member/2.png"
                      alt="member 1"
                      className="member-img"
                    />
                    <img
                      src="/img/member/3.png"
                      alt="member 1"
                      className="member-img"
                    />
                  </div>
                  <div className="current-member-info">
                    <h4>1000+</h4>
                    <p>Our Current Members</p>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BecomeAMember;
