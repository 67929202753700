import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import cn from "classnames";
import wellnessIvDrip from "../mocks/wellnessIvDrip.json";
import recoveryIvDrip from "../mocks/recoveryIvDrip.json";
import nadPlusIvDrip from "../mocks/nadPlusIvDrip.json";
import booster from "../mocks/boosters.json";
import aestheticDetails from "../mocks/aestheticDetails.json";

const allIvDrips = wellnessIvDrip.concat(recoveryIvDrip.concat(nadPlusIvDrip));

const menu = [
  {
    text: "Home",
    url: "/",
  },
  {
    text: "IV Therapy",
    url: "/services",
    subMenu: true,
    aesThetic: false,
  },
  {
    text: "Aesthetic",
    url: "/aesthetic-services",
    subMenu: true,
    aesThetic: true
  },
  {
    text: "Discover",
    url: "#",
  },
  {
    text: "Who We Are",
    url: "/who-we-are",
  },
  {
    text: "Contact",
    url: "/contact",
  },
];

const Header = () => {
  const navigate = useNavigate()
  const [activeIndex, setActiveIndex] = useState(0);
  const [dropdownShow, setDropdownShow] = useState(false);
  const [aesTheticShow, setAesTheticShow] = useState(false);
  const [showMblMenu, setShowMblMenu] = useState(false)
  return (
    <>
      <div className="top-nav text-center py-2 bg-dark text-light">
        <Link to='/iv-therapy/drips/view-all' className="text-light">
          20% discount on 3 or more sessions of IV Drip
        </Link>
      </div>
      <nav className="navbar sticky-top navbar-expand-lg navbar-light py-3 py-lg-4">
        <div className="container-lg">
          <Link className="navbar-brand" to="/">
            <img className="lg-logo d-none d-lg-block" src="/img/logo.png" alt="logo" />
            <img className="mbl-logo d-lg-none" src="/img/favicon.png" alt="logo" />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={() => {
              setShowMblMenu(!showMblMenu)
            }}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className={cn("collapse navbar-collapse mt-3 mt-lg-0", {"show" : showMblMenu})} id="navbarSupportedContent">
            <ul className="navbar-nav mx-auto">
              {menu.map((x, index) =>
                !x.subMenu ? (
                  <li className="nav-item position-relative" key={index}>
                    <Link
                      to={x.url}
                      onClick={() => {
                        setActiveIndex(index + 1);
                        setDropdownShow(false);
                        setAesTheticShow(false);
                        setShowMblMenu(false)
                      }}
                      className={cn("nav-link text-lg-center", {
                        active: index + 1 === activeIndex,
                      })}
                    >
                      {x.text}
                    </Link>
                  </li>
                ) : !x.aesThetic ? (
                  <li
                    className="nav-item dropdown service-dropdown"
                    onMouseOver={() => setDropdownShow(true)}
                    key={index}
                  >
                    <Link
                      className={cn("nav-link text-lg-center dropdown-toggle", {
                        active: index + 1 === activeIndex,
                      })}
                      to="#"
                      role="button"
                    >
                      {x.text}
                    </Link>
                    <ul
                      className={cn("dropdown-menu", {
                        "show-service": dropdownShow,
                      })}
                    >
                      <div className="dropdown-menu-wrap">
                        <div className="container">
                          <div className="row">
                            <div className="col-lg-2"></div>
                            <div className="col-lg-6">
                              <h5>Drips</h5>
                              <div className="row row-cols-2">
                                <li className="single-menu-item">
                                  <Link
                                    className="dropdown-item"
                                    to="/iv-therapy/view-all-drips"
                                    onClick={() => {
                                      setDropdownShow(false)
                                      setShowMblMenu(false)
                                    }}
                                  >
                                    View All
                                  </Link>
                                </li>
                                {allIvDrips.map((y, yIndex) => (
                                  <li className="single-menu-item" key={yIndex}>
                                    <Link
                                      className="dropdown-item"
                                      to={y.url}
                                      onClick={() => {
                                        setDropdownShow(false)
                                        setShowMblMenu(false)
                                      }}
                                    >
                                      {y.title}
                                    </Link>
                                  </li>
                                ))}
                              </div>
                            </div>
                            <div className="col-sm-3 mt-5 mt-sm-0">
                              <h5>Boosters</h5>
                              <li className="single-menu-item">
                                <Link
                                  className="dropdown-item"
                                  to="/iv-therapy/view-all-boosters"
                                  onClick={() => {
                                    setShowMblMenu(false)
                                    setDropdownShow(false)
                                  }}
                                >
                                  View All
                                </Link>
                              </li>
                              {booster.map((y, yIndex) => (
                                <li className="single-menu-item" key={yIndex}>
                                  <Link
                                    className="dropdown-item"
                                    to={y.url}
                                    onClick={() => {
                                      setDropdownShow(false)
                                      setShowMblMenu(false)
                                    }}
                                  >
                                    {y.title}
                                  </Link>
                                </li>
                              ))}
                              <li
                                className="single-menu-item"
                              >
                                <Link
                                  className="dropdown-item"
                                  to='#'
                                  onClick={(e) => {
                                    setAesTheticShow(false)
                                    setShowMblMenu(false)
                                  }}
                                >
                                  <h5 className="p-0 mt-3">IV Drip party</h5>
                                </Link>
                              </li>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ul>
                  </li>
                ) : (
                  <li
                    onClick={() => {
                      setActiveIndex(index + 1);
                      setAesTheticShow(!aesTheticShow);
                      setDropdownShow(false);
                    }}
                    className="nav-item dropdown aesthetic-dropdown"
                    key={index}
                    onMouseOver={() => setAesTheticShow(true)}
                  >
                    <Link
                      className={cn("nav-link text-lg-center dropdown-toggle", {
                        active: index + 1 === activeIndex,
                      })}
                      to="#"
                      role="button"
                    >
                      {x.text}
                    </Link>
                    <ul
                      className={cn("dropdown-menu", {
                        "show-aesthetic": aesTheticShow,
                      })}
                    >
                      <div className="dropdown-menu-wrap">
                        <div className="container">
                          <div className="row">
                            <div className="col-12">
                              <div className="row">
                                <div className="col-lg-2"></div>
                                <div className="col-lg-8">
                                  <h5>Learn About our Services</h5>
                                  <div className="row row-cols-md-3 row-cols-sm-2 row-cols-1">
                                      <li
                                        className="single-menu-item"
                                      >
                                        <Link
                                          className="dropdown-item"
                                          to={`/view-all-aesthetic`}
                                          onClick={(e) => {
                                            setAesTheticShow(false)
                                            setShowMblMenu(false)
                                          }}
                                        >
                                          View All
                                        </Link>
                                      </li>
                                    {aestheticDetails.map((y, yIndex) => (
                                      <li
                                        className="single-menu-item"
                                        key={yIndex}
                                      >
                                        <Link
                                          className="dropdown-item"
                                          to={y.url}
                                          onClick={(e) => {
                                            setAesTheticShow(false)
                                            setShowMblMenu(false)
                                            console.log(y.url)
                                          }}
                                        >
                                          {y.title}
                                        </Link>
                                      </li>
                                    ))}
                                  </div>
                                </div>
                                <div className="col-lg-2"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ul>
                  </li>
                )
              )}
            </ul>
          </div>
          <div className="header-right d-flex flex-column flex-lg-row gap-3 align-items-center">
            <Link
              to={"https://ivhub.com/book/"}
              className="main-btn btn" 
            >
              Book Now
            </Link>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Header;
