import React from 'react';

const Failed = () => {
    return (
        <div className="failed-page py-5">
          <div className="container">
          <div className="hero p-5">
              <h1>
              Transaction Failed!
              </h1><br/>
              <h4>Please Try to Book Again.</h4>
            </div>
            
          </div>
        </div>
    );
};

export default Failed;