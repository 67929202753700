import React from 'react';

const Thankyou = () => {
    return (
        <div className="thankyou-page py-5">
          <div className="container">
          <div className="hero p-5">
              <h1>
              Thank you for booking with us.
              </h1><br/>
              <h4>Enjoy your Drip</h4>
            </div>
            
          </div>
        </div>
    );
};

export default Thankyou;