import React, { useState } from 'react';
import boosters from '../mocks/boosters.json';
import BecomeAMember from '../Components/BecomeAMember';
import SingleBooster from '../Components/SingleBooster';
import LeftModal from '../Components/LeftModal';

const Booster = () => {
  const [currentBooster, setCurrentBooster] = useState(boosters[0])
    return (
        <div className="boosters-page">
          <div className="hero">
           
          </div>
          <div className="drips-wrapper pb-5">
            <div className="container">
              <div className="row">
                {boosters.map((x, index) => (
                  <div key={index} className="col-lg-6 mt-5 d-flex justify-content-center">
                    <SingleBooster
                      img={x.img}
                      text={x.text}
                      title={x.title}
                      name={x.name}
                      currency={x.currency}
                      price={x.price}
                      onClick={() => setCurrentBooster(x)}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
          <BecomeAMember />
      <LeftModal 
        boosterImg={currentBooster?.img}
        title={currentBooster?.title}
        slogan={currentBooster?.name}
        para1={currentBooster?.text}
        currency={currentBooster?.currency}
        price={currentBooster?.price}
      />
        </div>
    );
};

export default Booster;