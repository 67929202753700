import React from "react";

const CancellationPolicy = () => {
  return (
    <div className="cancellation-page py-5">
      <div className="container">
        <div className="hero p-5">
          <h1>
            Cancellations <span>Policy</span>
          </h1>
        </div>
        <h2>Cancellation Policy – Locations</h2>
        <p>
          We understand that there are times where emergencies or obligations
          for family or work may arise. However, failing to call and reschedule
          or cancel your appointment denies other clients the ability to book
          their own appointment, due to a seemingly reserved position.
          <br />
          <br />
          Cancellations with less than a 12-hour notice will be charged 50% of
          the reserved appointment price to the held credit card on file. We
          will continue to pre-screen for COVID-19 and we ask that if you are
          exhibiting symptoms or have been exposed to someone who has tested
          positive for COVID-19 to cancel or reschedule your appointment with at
          least 12-hours notice to avoid any fees.
          <br />
          <br />
          No-shows will be charged in full to the card held on file.
        </p>
        <h2>Late Arrivals</h2>
        <p>
          We understand that delays may occur. However, we must try to keep
          clients from delaying subsequent appointments and keep services on
          time. If you arrive more than ten minutes past your scheduled
          appointment time, we may not be able to serve you, though we will try
          to accommodate you when possible. If we are unable to reschedule you,
          a late cancellation fee may apply.
        </p>
        <h2>Cancellation Policy – Group Bookings</h2>
        <p>
          We understand that there are times where emergencies or obligations
          for family or work may arise. However, failing to call and reschedule
          or cancel your appointment denies other clients the ability to book
          their own appointment, due to a seemingly reserved position.
          <br />
          <br />
          Cancellations with less than a 12-hour notice will be charged 50% of
          the reserved appointment price to the held credit card on file in
          accordance with the cancellation policy. We will continue to
          pre-screen for COVID-19 and we ask that if you are exhibiting symptoms
          or have been exposed to someone who has tested positive for COVID-19
          to cancel or reschedule your appointment with at least 12-hours notice
          to avoid any fees. If rescheduled, different fees apply, see below.
          <br />
          <br />
          If a nurse has already been dispatched to the client’s location, or if
          you are not present at the given address within 10 minutes of the
          nurse’s arrival, you will be charged in full to the held card on file.
        </p>
        <h2>Nurse Deployment Fee</h2>
        <p>
          In the event that an infusion is aborted, the client will be charged
          the concierge fee. This fee applies in the following circumstances,
          including but not limited to: -Nurse dispatched but client unable to
          receive drip because information was not disclosed at the time of
          booking (example: alcohol withdrawal, drug abuse) <br />
          -Nurse dispatched but client unable to receive drip because vitals
          signs are out of range <br />
          -Nurse dispatched, IV not completed due to the client’s condition
          (example: could not tolerate it, Vasovagaled and did not want to
          continue) <br />
          -Nurse dispatched but IV not completed although flowing well because
          of time constraints <br />
          -Unsafe or unsanitary environment <br />
          This fee will NOT apply if: <br />
          -Nurse dispatched but unable to start IV after 3 attempts. Note: As
          per the terms of service, the nurse must be allowed to attempt the
          placement at least thrice. If the client chooses not to continue after
          the first attempt, the 50% of the concierge fee will apply. <br />
          This fee will also apply should the client facilitate a hostile or
          unsafe environment as perceived by the nurse during the visit. This
          provision does not apply if the session proceeds normally in which
          case the client will only be liable for the cost of the IV drip.
        </p>
      </div>
    </div>
  );
};

export default CancellationPolicy;
