import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const reviews = [
  {
    img: "/img/review/review.svg",
    review:
      "The venue is so chic and calm, perfect for an hour of IV therapy.",
  },
  {
    img: "/img/review/review.svg",
    review:
      "I tried the immune IV drip and I am happy with the result.",
  },
  {
    img: "/img/review/review.svg",
    review:
      "Dr Anita is a very talented doctor and sculptor! I went in to touch up my lip filler and add some lift to my cheekbones. I ended up adding on skin rejuvenation too! ",
  },
  {
    img: "/img/review/review.svg",
    review:
      "Dr. Anita is a sculpting goddess I am so happy with her work and explained everything she was about to do and why! ",
  },
  {
    img: "/img/review/review.svg",
    review:
      "I did botox on my forehead and in between my eyes. The results are incredible!! ",
  },
  {
    img: "/img/review/review.svg",
    review:
      "I've been getting hydrafacials with IV Lounge for months and it's one of the best decisions I've ever made.  ",
  },
  {
    img: "/img/review/review.svg",
    review:
      "LIP FILLERS the best decision! I wanted something natural and that would help me improve my smile and harmonize my face, the whole process with the best care and attention ....  ",
  },
];

const responsive = {
  desktop: {
    breakpoint: { max: 8000, min: 992 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 991, min: 768 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 767, min: 0 },
    items: 1,
  },
};

const Reviews = () => {
  return (
    <div className="review-section my-5">
      <div className="container">
        <div className="review-wrap py-5 pb-5">
            <div className="press px-5 mb-5"></div>
            <h1 className="mt-3 text-center mb-4">What People Are Saying</h1>
            <h4 className="text-center mb-5">You're making us blush!</h4>
            <a className="text-center read-review" href="https://g.co/kgs/VDXd4j">Read our reviews</a>
        <div className="carousel mt-5">
        <Carousel
          autoPlay={true}
          autoPlaySpeed={3000}
          infinite={true}
          showDots={false}
          arrows={false}
          responsive={responsive}
        >
          {reviews.map((x, index) => (
            <div key={index} className="mb-3">
              <div className="single-review text-center mx-3 p-3">
                <h3>{x.review}</h3>
                <img src={x.img} alt="img" className="img-fluid mt-5" />
              </div>
            </div>
          ))}
        </Carousel>
        </div>
        </div>
      </div>
    </div>
  );
};

export default Reviews;
