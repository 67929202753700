import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FiChevronRight } from "react-icons/fi";
import SingleHub from "../Components/SingleHub";
import BecomeAMember from "../Components/BecomeAMember";
import { AiFillCaretDown } from "react-icons/ai";
import cn from "classnames";
import OutsideClickHandler from "react-outside-click-handler";
import Reviews from "../Components/Reviews";

const gotDetails = [
  "Crow's Feet",
  "Dark Spot",
  "Dullness",
  "Loss of Firmness",
  "Loss of Elasticity",
  "Dryness",
  "Breakouts",
  "Love Handles",
  "Redness",
  "Winter-Bod"
];
const weveGotDetails = [
  "Line & Wrinkle Relaxer",
  "Chemical Peel",
  "Skin Boosters",
  "Dermal Filter",
  "Dermal Filter",
  "Hydrafacial",
  "Chemical peel",
  "LipoSculpt",
  "Skin Boosters",
  "BodySculpt"
];

const Home = () => {
  const [selectedGot, setSelectedGot] = useState(gotDetails[0]);
  const [got, setGot] = useState(gotDetails.filter((e) => e !== selectedGot));
  const [showGotDropdown, setShowGotDropdown] = useState(false);
  const [selectedWeveGot, setSelectedWeveGot] = useState(weveGotDetails[0]);
  const [weveGot, setWeveGot] = useState(
    weveGotDetails.filter((e) => e !== selectedWeveGot)
  );
  const [showWeveGotDropdown, setShowWeveGotDropdown] = useState(false);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const randomIndex = Math.floor(Math.random() * gotDetails.length);
      setSelectedGot(gotDetails[randomIndex])
    }, 4000);

    return () => {
      clearInterval(intervalId);
    };
  });

  useEffect(() => {
    setGot(gotDetails.filter((e) => e !== selectedGot));
  }, [selectedGot]);

  useEffect(() => {
    setWeveGot(weveGotDetails.filter((e) => e !== selectedWeveGot));
  }, [selectedWeveGot]);

  useEffect(
    () =>
      selectedGot === gotDetails[0]
        ? setSelectedWeveGot(weveGotDetails[0])
        : selectedGot === gotDetails[1]
        ? setSelectedWeveGot(weveGotDetails[1])
        : selectedGot === gotDetails[2]
        ? setSelectedWeveGot(weveGotDetails[2])
        : selectedGot === gotDetails[3]
        ? setSelectedWeveGot(weveGotDetails[3])
        : selectedGot === gotDetails[4]
        ? setSelectedWeveGot(weveGotDetails[3])
        : selectedGot === gotDetails[5]
        ? setSelectedWeveGot(weveGotDetails[4])
        : selectedGot === gotDetails[6]
        ? setSelectedWeveGot(weveGotDetails[5])
        : selectedGot === gotDetails[7]
        ? setSelectedWeveGot(weveGotDetails[4])
        : selectedGot === gotDetails[8]
        ? setSelectedWeveGot(weveGotDetails[6])
        : selectedGot === gotDetails[9]
        ? setSelectedWeveGot(weveGotDetails[1])
        : selectedGot === gotDetails[10]
        ? setSelectedWeveGot(weveGotDetails[6])
        : null,
    [selectedGot]
  );

  return (
    <div className="home pt-5">
      {/* hero section */}
      <div className="hero">
        <div className="container-fluid">
          <div className="wrap">
            <div className="row align-items-center">
              <div className="col-lg-5">
                <div className="left d-flex flex-column">
                  <video autoPlay muted loop>
                    <source src="/video/hero.mp4" type="video/mp4" />
                  </video>
                </div>
              </div>
              <div className="col-lg-7 mt-5 mt-lg-0 ps-lg-5 py-5">
                <div className="right text-center px-5">
                  <h3>Book your next service today</h3>
                  <h1>
                    DUBAI'S Most Premium Wellness & Beauty Lounge
                  </h1>
                  <p>
                    IV Wellness Lounge is the place to be if you want be at your
                    <br /> best inside out.
                  </p>
                  <Link
              to={"view-all-aesthetic"}
              className="main-btn btn" 
            >
              &nbsp;&nbsp;Aesthetic
            </Link>&nbsp;
            <Link
              to={"/iv-therapy/drips/view-all"}
              className="main-btn btn" 
            >
              IV Therapy
            </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* discover section */}
      <div className="discover py-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="left">
                <h1>Begin Your Wellness & Beauty Journey</h1>
                <p className="pe-lg-5">
                IV Wellness Lounge Clinic is a premium wellness lounge providing an upscale boutique experience. 
                We provide aesthetic services and IV Infusion therapy with proven results, within your means. 
                IV Wellness is a space where you can wallow in comfort while we provide preventive health and cosmetic solutions. Our lounge is elegantly designed to give you the most top-notch services with top-rated health care professionals and therapists. 
                <br/><br/>
                IV Wellness Lounge is the place to be if you want be at your best inside out.</p>
                  <div className="mt-4">
                  <Link
                    to={"/contact"}
                    className="discover-btn"
                  >
                  </Link>
                  </div>
              </div>
            </div>
            <div className="col-lg-6 mt-5 mt-lg-0">
              <div className="right">
                <img
                  className="img-fluid"
                  src="/img/discover/1.png"
                  alt="discover"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Dropdown section */}
      <div className="got-section mb-5">
        <div className="container">
          <div className="wrap d-flex flex-column flex-lg-row align-items-center justify-content-between">
            <div className="left mt-5">
              <OutsideClickHandler
                onOutsideClick={() => setShowGotDropdown(false)}
              >
                <h1>
                  <span>Got</span>
                  <div
                    onClick={() => setShowGotDropdown(!showGotDropdown)}
                    className="select-head"
                  >
                    {selectedGot}
                    <span>
                      <AiFillCaretDown />
                    </span>
                  </div>
             
                  <ul className={cn({ show: showGotDropdown })}>
                    {got.map((x, index) => (
                      <li
                        onClick={() => {
                          setShowGotDropdown(false);
                          setSelectedGot(x);
                        }}
                        key={index}
                      >
                        {x}
                      </li>
                    ))}
                  </ul>
                </h1>
              </OutsideClickHandler>
              <OutsideClickHandler
                onOutsideClick={() => setShowWeveGotDropdown(false)}
              >
                <h1 className="mt-4 mt-lg-3">
                  <span>We've got</span>
                  <div
                    onClick={() => setShowWeveGotDropdown(!showWeveGotDropdown)}
                    className="select-head"
                  >
                    {selectedWeveGot}
                    <span>
                      <AiFillCaretDown />
                    </span>
                  </div>
                  <ul className={cn({ weveShow: showWeveGotDropdown })}>
                    {weveGot.map((x, index) => (
                      <li
                        onClick={() => {
                          setShowWeveGotDropdown(false);
                          setSelectedGot(
                            x === weveGotDetails[0]
                              ? gotDetails[0]
                              : x === weveGotDetails[1]
                              ? gotDetails[1]
                              : x === weveGotDetails[2]
                              ? gotDetails[2]
                              : x === weveGotDetails[3]
                              ? gotDetails[3]
                              : x === weveGotDetails[4]
                              ? gotDetails[5]
                              : x === weveGotDetails[5]
                              ? gotDetails[6]
                              : x === weveGotDetails[6]
                              ? gotDetails[8]
                              : null
                          );
                        }}
                        key={index}
                      >
                        {x}
                      </li>
                    ))}
                  </ul>
                </h1>
              </OutsideClickHandler>
            </div>
            <div className="right">
            <Link
              to={"/view-all-aesthetic"}
              className="main-btn btn" 
            >
              Learn More..
            </Link>
            </div>
          </div>
        </div>
      </div>
      {/* Drip section */}
      {/* <div className="drip py-5">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="d-flex flex-column flex-lg-row align-lg-item-end justify-content-center gap-5">
                <div className="left">
                  <h1>Drip Menu</h1>
                  <h2>
                    20% discount <span>on 3 or more sessions</span>
                  </h2>
                </div>
                <Link className="btn main-btn" to="/iv-therapy/drips/view-all">
                  <span>Explore All Drips</span>
                  <FiChevronRight />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* Therapy */}
      {/* Become a member*/}
      <Reviews />
      <BecomeAMember />
    </div>
  );
};

export default Home;
