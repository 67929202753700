import React, { useState } from 'react';
import Header from './Header';
import Footer from './Footer';
import Booking from "../Pages/Booking";
import ScrollToTop from './ScrollToTop';

const Page = ({ content }) => {
    return (
        <ScrollToTop>
            <Header />
            <div className="page-content">
                {content}
            </div>
            <Footer />
            <Booking />
        </ScrollToTop>
    );
};

export default Page;