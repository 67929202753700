import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FiChevronRight } from "react-icons/fi";
import cn from "classnames";
import SingleHub from "../Components/SingleHub";
import wellnessIvDrip from "../mocks/wellnessIvDrip.json";
import recoveryIvDrip from "../mocks/recoveryIvDrip.json"
import nadPlusIvDrip from '../mocks/nadPlusIvDrip.json'
import BecomeAMember from "../Components/BecomeAMember";
import LeftModal from '../Components/LeftModal';

const therapyInfo = [
  {
    icon: "/img/therapy/icon1.png",
    title: "Select Your Drip",
    text: "Pick the Drip best suited to your specific needs, or have our highly trained medical team find the right one for you.",
  },
  {
    icon: "/img/therapy/icon2.png",
    title: "Schedule Your Appointment",
    text: "Schedule an appointment via our booking page or book a drip concierge to visit your home, office or hotel.",
  },
  {
    icon: "/img/therapy/icon3.png",
    title: "Quick Medical Consult",
    text: "Our licensed medical providers will review your medical history and vitals.",
  },
  {
    icon: "/img/therapy/icon4.png",
    title: "Enjoy Your Drip",
    text: "Begin feeling refreshed and revitalized as you soak in electrolytes and nutrients. *The infusion process typically takes 30-60 minutes depending on the IV drip you choose.",
  },
];

const dripMenu = [
  {
    text: "Wellness IV Drips",
    url: "/services/drips/wellness-iv-drip",
  },
  {
    text: "Recovery & Hangover IV Drips",
    url: "/services/drips/recovery-hangover-iv-rips",
  },
  {
    text: "NAD+",
    url: "/services/drips/nad+",
  },
];

const allIvDrips = wellnessIvDrip.concat(recoveryIvDrip.concat(nadPlusIvDrip))

const Drip = ({
  activeWellnessIvDrips,
  activeRecoverIvDrips,
  activeNadIvDrips,
  activeAllIvDrips,
}) => {
  const [activeDripMenu, setActiveDripMenu] = useState(1);
  const [currentKnowMoreDrip, setCurrentKnowMoreDrip] = useState(allIvDrips[0])

  useEffect(() => {
    setActiveDripMenu(
      (activeWellnessIvDrips && 1) ||
        (activeRecoverIvDrips && 2) ||
        (activeNadIvDrips && 3)
    );
  });
 
  return (
    <div className="drip-page">
      {activeAllIvDrips ? (
        <div className="all-drip-hero mb-5">
          <div className="all-drip-wrap">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h1>Personalized <span>IV Drip</span> <br /> Nutrient Therapy</h1>
                <p>So, you can feel your absolute best!</p>
              </div>
            </div>
          </div>
          </div>
        </div>
      ) : (
        <div className="hero">
        <div className="container py-5">
          <div className="row hero-wrap align-items-center">
            <div className="col-lg-6 p-5">
              <div className="left text-center">
                <h3>Book your next service today</h3>
                <h1>
                  {(activeWellnessIvDrips && "Wellness") ||
                    (activeRecoverIvDrips && "Recover & Hangover") ||
                    (activeNadIvDrips && "NAD+")}
                  <em>IV Drips</em>
                </h1>
                <p>
                  20% discount on 3 or more <br /> sessions
                </p>
              </div>
            </div>
            <div className="col-lg-6 align-self-end">
              <div className="right text-center text-lg-end">
                <img
                  className="mt-5 img-fluid drip-bg"
                  src={
                    (activeWellnessIvDrips && "/img/drip/drip-hero1.png") ||
                    (activeRecoverIvDrips && "/img/drip/drip-hero2.png") ||
                    (activeNadIvDrips && "/img/drip/drip-hero3.png") ||
                    (activeAllIvDrips && "/img/drip/drip-hero4.png")
                  }
                  alt="drip-bg"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      )}
      <div className="therapy py-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 mb-5">
              <div className="top text-center">
                <h1>IV Therapy Personalized For You</h1>
                <p>
                  We all have different health goals and nutritional needs. Our
                  medical team will help you find and customize the right IV
                  Drip protocol, so you can feel your absolute best.
                </p>
              </div>
            </div>
            <div className="col-12">
              <div className="right d-flex flex-wrap position-relative">
                {therapyInfo.map((x, index) => (
                  <div key={index} className="single-info mt-5">
                    <div className="icon-wrap">
                      <img src={x.icon} alt="icon" />
                    </div>
                    <h2>{x.title}</h2>
                    <p>{x.text}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="drip-menu py-5">
        <div className="container">
          <div className="row align-items-center">
            <div className={cn(activeAllIvDrips ? "col-12" : "col-lg-9")}>
              <div className={cn("left d-flex align-items-center gap-3", {"justify-content-center" : activeAllIvDrips})}>
                {dripMenu.map((x, index) => (
                  <Link
                    key={index}
                    to={x.url}
                    className={cn("drip-menu-item", {
                      active: activeDripMenu === index + 1,
                    })}
                  >
                    {x.text}
                  </Link>
                ))}
              </div>
            </div>
            {activeAllIvDrips ? null : (
            <div className="col-lg-3 d-flex justify-content-lg-end mt-5 mt-lg-0">
              <Link className="btn main-btn mt-0" to="/iv-therapy/drips/view-all">
                <span>Explore All Drips</span>
                <FiChevronRight />
              </Link>
            </div>
            )}
          </div>
        </div>
      </div>
      <div className="drips-wrapper pb-5">
        <div className="container">
          <div className="row">
            {(activeWellnessIvDrips && wellnessIvDrip || activeRecoverIvDrips && recoveryIvDrip || activeNadIvDrips && nadPlusIvDrip || activeAllIvDrips && allIvDrips).map((x, index) => (
              <div key={index} className="col-lg-4 col-sm-6 mt-5 d-flex justify-content-center">
                <SingleHub
                  img={x.img}
                  text={x.subTitle}
                  title={x.title}
                  currency={x.currency}
                  price={x.price}
                  onClick={() => setCurrentKnowMoreDrip(x)}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
      <BecomeAMember />
      <LeftModal 
        dripsImg={currentKnowMoreDrip?.img}
        title={currentKnowMoreDrip?.title}
        slogan={currentKnowMoreDrip?.subTitle}
        para1={currentKnowMoreDrip?.paraOne}
        para2={currentKnowMoreDrip?.paraTwo}
        benefitTitle={currentKnowMoreDrip?.benefit?.title}
        benefitList={currentKnowMoreDrip?.benefit?.list}
        keyInstrument={currentKnowMoreDrip?.keyInstrument}
        currency={currentKnowMoreDrip?.currency}
        price={currentKnowMoreDrip?.price}
      />
    </div>
  );
};

export default Drip;
