import React from "react";

const Consultation = () => {
  return (
    <div className="consultation mt-5 py-5">
      <div className="container position-relative my-5">
        <img src="/img/live.svg" alt="live" className="live" />
        <div className="row align-items-center">
          <div className="col-lg-7">
            <div className="left">
              <h1 className="my-5">Not Sure What Service is Right For You?</h1>
              <p>
                Talk with one of our expert providers to ask questions and
                determine what treatment is right for you
              </p>
            </div>
          </div>
          <div className="col-lg-5 mt-5 mt-lg-0">
            <div className="right mt-4 mt-lg-0 text-lg-end">             
                <a class="main-btn btn" href="https://wa.me/+971585748444?text=Hello, I would like to know more about your services.">SCHEDULE A CONSULTATION</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Consultation;
